.checkmark-icon {
  display: block;
  margin: auto;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.checkmark-circle {
  stroke-dasharray: 157;
  stroke-dashoffset: 157;
  animation: circle-animation 0.6s ease forwards;
}

.checkmark-check {
  stroke-dasharray: 36;
  stroke-dashoffset: 36;
  animation: check-animation 0.3s 0.6s ease forwards;
}

@keyframes circle-animation {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes check-animation {
  to {
    stroke-dashoffset: 0;
  }
}
.animated-dots span {
  animation: dot-blink 1.4s infinite steps(1, end);
  font-weight: bold;
  font-size: 1.2em;
  opacity: 0;
}

.animated-dots span:nth-child(1) {
  animation-delay: 0.2s;
}

.animated-dots span:nth-child(2) {
  animation-delay: 0.4s;
}

.animated-dots span:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes dot-blink {
  0%,
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
